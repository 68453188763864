export default {
  props: {
    blinkRowItemKey: {
      default: undefined
    },
  },

  data() {
    return {
      isRowBlinked: false
    };
  },

  watch: {
    items() {
      this.checkForBlinkRow();
    },

    serverItems() {
      this.checkForBlinkRow();
    },
  },

  mounted() {
    if (this.blinkRowItemKey) {
      this.$refs.table.$el
        .querySelector('.v-data-table__wrapper')
        .addEventListener('scroll', this.checkForBlinkRow);
    }
  },

  beforeDestroy() {
    if (this.blinkRowItemKey) {
      this.$refs.table.$el
        .querySelector('.v-data-table__wrapper')
        .removeEventListener('scroll', this.checkForBlinkRow);
    }
  },

  methods: {
    checkForBlinkRow() {
      if (this.blinkRowItemKey && !this.isRowBlinked) {
        let items = this.serverItems || this.items;

        let item = items.find(item => item[this.itemKey] == this.blinkRowItemKey);

        if (item) {
          this.$nextTick(() => {
            let row = this.$refs.table.$el.querySelector(`.row-item-key-${item[this.itemKey]}`);

            if (this.isRowVisible(row)) {
              row.classList.add('blink-row');

              this.isRowBlinked = true;

              this.$refs.table.$el
                .querySelector('.v-data-table__wrapper')
                .removeEventListener('scroll', this.checkForBlinkRow);
            }
          });
        }
      }
    },

    isRowVisible(row) {
      let scrolledElRect = this.$refs.table.$el
        .querySelector('.v-data-table__wrapper')
        .getBoundingClientRect();

      let rowRect = row.getBoundingClientRect();

      return rowRect.top >= scrolledElRect.top &&
        rowRect.bottom <= scrolledElRect.bottom;
    },
  }
};
