<template>
	<v-container fluid class="main-container">
    <v-overlay
      :absolute="true"
      :value="error"
    >
      <v-alert
        prominent
        type="error"
      >
        <v-row align="center">
          <v-col class="grow">
            {{ errorMessage }}
          </v-col>
          <v-col class="shrink">
            <v-btn color="primary" @click="handleReloadPageClick()">{{ $t('GENERAL.LABELS.RELOAD_PAGE') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <InterestsList
        :entity="entity"
        @entity-data-updated="handleEntityDataUpdated()"
        @edit-grouped-mapping-field-clicked="$emit('edit-grouped-mapping-field-clicked', $event)"
        @clone-interests-clicked="$emit('clone-interests-clicked')"
        @error="handleError($event)"
    />
    <PriceLists
      v-if="entity.type === 'opportunity'"
      ref="priceLists"
      class="mt-5"
      :entity="entity"
      :vimsEntity="vimsEntity"
      :isAppLoading="isAppLoading"
      @price-list-clicked="$emit('price-list-clicked', $event)"
      @error="handleError($event)"
    />
  </v-container>
</template>

<script>
import InterestsList from "@/views/pages/copper/components/InterestsList.vue";
import PriceLists from "@/views/pages/copper/components/PriceLists.vue";

export default {
  components: {
    InterestsList,
    PriceLists
  },

  props: {
    entity: {
      type: Object
    },

    vimsEntity: {
      type: Object
    },

    isAppLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      error: false,
      errorMessage: this.$t('GENERAL.UNEXPECTED_ERROR')
    };
  },

  methods: {
    handleEntityDataUpdated() {
      if (
        this.entity.type === 'opportunity' &&
        this.vimsEntity.is_price_list_search_inited &&
        this.vimsEntity.is_in_active_pipeline_stage
      ) {
        this.$refs.priceLists.fetchPriceLists();
      }
    },

    handleReloadPageClick() {
      window.location.reload();
    },

    handleError(error) {
      if (error) {
        this.errorMessage = error;
      }

      this.error = true;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
