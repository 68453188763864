<template>
  <div>
    <label class="select-label" v-if="label">{{ label }}</label>
    <v-select
      multiple
      chips
      v-bind="$attrs"
      :value="value"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      class="pt-0 mt-0"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    >
      <template
        v-slot:append
      >
        <div>
          <v-chip v-if="allSelected || (allChipForNothingSelected && value.length === 0)">
            <span>{{ $t('GENERAL.LABELS.ALL') }}</span>
          </v-chip>
          <div class="v-input__icon v-input__icon--append input-carrot-wrapper">
            <i class="v-icon notranslate mdi mdi-menu-down theme--light"></i>
          </div>
        </div>
      </template>
      <template
        v-if="allSelected"
        v-slot:selection
      ></template>
      <template v-slot:prepend-item>
        <v-list-item
          ripple
          @mousedown.prevent
          @click="toggle"
        >
          <v-list-item-action>
            <v-icon :color="allSelected ? 'primary' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('GENERAL.LABELS.SELECT_ALL_OPTION') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </div>
</template>

<script>
import { map } from "lodash";

export default {
  props: {
    label: {
      type: String,
      required: false
    },

    value: {
      type: Array,
      default: () => []
    },

    items: {
      type: Array,
      default: () => []
    },

    itemText: {
      required: false
    },

    itemValue: {
      required: false
    },

    allChipForNothingSelected: {
      type: Boolean,
      default: false
    }
  },

  computed: {
      allSelected() {
        return this.value.length === this.items.length
      },

      icon() {
        return this.allSelected ?
          'mdi-checkbox-marked' :
          'mdi-checkbox-blank-outline';
      },
    },

    methods: {
      toggle() {
        this.$nextTick(() => {
          let value = [];

          if (!this.allSelected) {
            const isObjects = this.items.length && typeof this.items[0] === 'object';

            if ((this.itemText || this.itemValue) && isObjects) {
              this.items.forEach(item => {
                value.push(item[this.itemValue || this.itemText]);
              });
            } else {
              value = map(this.items, 'value');
            }
          }

          this.$emit('input', value);
          this.$emit('change', value);
        });
      }
    }
};
</script>

<style lang="scss" scoped>
  .select-label {
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0;
  }

  .input-carrot-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  :deep(.v-select__selections) {
    order: 2;
  }
</style>
