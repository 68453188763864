<template>
    <TPAutocomplete
        v-if="initialized"
        :value="value"
        :getter="companyContactGetter"
        :items="items"
        :filter="() => true"
        :defaults="defaults"
        :itemSlot="true"
        label="Company Contact"
        placeholder="Start typing to Search"
        prepend-icon="person"
        item-value="id"
        :display-fields="['name', 'email', 'company_name']"
        :rules="notRequired?[]:[validations.required()]"
        :menu-props="contactAutocompleteMenuProps"
        :clearable="clearable"
        :disabled="disabled"
        :error="error"
        @click="contactAutocompleteMenuProps.value = true"
        @loaded="contactAutocompleteMenuProps.value = true"
        @blur="handleBlurAutocompleteMenu"
        @input="handleInput"
        @change="$emit('change', $event)"
    >
        <template v-slot:item="{ item }">
            <TPAddContact
                v-if="!item.id"
                @processing="handleProcessing"
                @processed="handleProcessed"
                @new-contact="handleNewContact"
                :show-panel="showPanel"
            ></TPAddContact>
            <span v-else-if="item.id === -1">{{ item.text }}</span>
            <span v-else>{{ [item.name, item.email, item.company_name].join(', ') }}</span>
        </template>
    </TPAutocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import { SEARCH_COMPANY_CONTACTS } from "@/store/companyContact.module";
import validations from "@/views/partials/form/Validations.vue";
import TPAutocomplete from "@/views/partials/form/Autocomplete.vue";
import TPAddContact from "./AddContact";

export default {
    components: {
        TPAutocomplete,
        TPAddContact
    },
    props: {
        value: Number,
        notRequired:{
            type: Boolean,
            default: false
        },
        showPanel: {
            type: Boolean,
            default: true
        },
        showAddNew: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        defaults:Object
    },
    data() {
        return {
            initialized: false,
            validations: validations,
            items: [],
            companyContactGetter: SEARCH_COMPANY_CONTACTS,
            contactAutocompleteMenuProps: {
                value: false
            }
        };
    },
    computed: {
        ...mapGetters(["companyContacts"])
    },
    watch: {
        companyContacts() {
            this.prepareItems();
        }
    },
    mounted() {
        if (this.value) {
          this.$store.dispatch(SEARCH_COMPANY_CONTACTS, this.value);
        } else {
          this.prepareItems();
        }
    },
    methods: {
        prepareItems() {
            this.items = [...this.companyContacts];

            if (!this.items.length) {
                this.items.push({
                    id: -1,
                    text: 'no data available',
                    disabled: true
                });
            }

            this.items.push({
                divider: true
            });

            if (this.showAddNew) {
              this.items.push({
                  name: 'Add New Contact'
              });
            }

            this.initialized = true;
        },
        handleInput(value) {
            // if (value) {
                this.$emit("input", value);
                this.handleBlurAutocompleteMenu();
            // }
        },
        handleNewContact(id) {
            this.handleInput(id);
        },
        handleBlurAutocompleteMenu() {
            setTimeout(() => {
                this.contactAutocompleteMenuProps.value = false;
            }, 100);
        },
        handleProcessing() {
            this.$emit("processing");
        },
        handleProcessed() {
            this.$emit("processed");
        }
    }
};
</script>
