
<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="60vw">
      <template v-if="!hideButton" v-slot:activator="{ on, attrs }">
        <v-btn color="red ml-2 mr-2 lighten-2" dark @click="showDialog()">
          Print View
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline red lighten-1"> Print View </v-card-title>

        <v-card-text class="p-0">
          <tiptap-vuetify v-model="content" :extensions="extensions" />
        </v-card-text>
        <textarea
          id="copyText"
          :value="
            content
              .replace(/<p><strong>/g, '')
              .replace(/<\/strong><\/p>/g, '\n')
              .replace(/<p>/g, '')
              .replace(/<\/p>/g, '\n')
          "
        ></textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="copy()"> Copy </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import { SHOW_SNACK } from "@/store/snack.module";

export default {
  components: { TiptapVuetify },
  props: ["getContent", "hideButton"],
  data: () => ({
    content: "",
    dialog: false,
    extensions: [ListItem, OrderedList, Bold],
  }),
  methods: {
    showDialog() {
      this.content = this.getContent();
      this.dialog = true;
    },
    copy() {
      let copy = document.querySelector("#copyText");
      copy.setAttribute("type", "text");
      copy.select();
      try {
        document.execCommand("copy");
        this.$store.dispatch(SHOW_SNACK, {
          message: "Offer was copied successful.",
        });
      } catch (err) {}

      /* unselect the range */
      copy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>
<style scoped>
.theme--light.v-toolbar.v-sheet {
  display: none !important;
}
#copyText {
  width: 1px !important;
  height: 1px !important;
  top: -300%;
  position: absolute;
}
</style>