<template>
	<div>
    <v-row v-if="loading && !isAppLoading">
      <v-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <v-progress-circular
          :size="50"
          indeterminate
          color="primary"
        />
      </v-col>
    </v-row>
    <template v-else-if="!loading">
      <v-row v-if="(!vimsEntity.is_price_list_search_inited) || (!vimsEntity.is_in_active_pipeline_stage)">
        <v-col
          cols="12"
          class="pt-0 mb-2 d-flex justify-content-center"
        >
          <v-btn
            rounded
            color="primary"
            dark
            height="30"
            @click="
              !vimsEntity.is_price_list_search_inited ?
                handleRequestQuoteClick() :
                fetchPriceLists(true)
              "
          >
            {{ $t('GENERAL.LABELS.REQUEST_QUOTE') }}
          </v-btn>
        </v-col>
      </v-row>
      <template v-if="vimsEntity.is_price_list_search_inited">
        <v-row>
          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <h1 class="price-lists-heading">{{ $t('GENERAL.LABELS.PRICE_LISTS') }}:</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <div
              v-for="priceList in preparedPriceLists"
              class="price-list mt-5 set-cursor-pointer"
              @click="$emit('price-list-clicked', { priceListId: priceList.id })"
            >
              <div class="price-list-date mb-2">
                <b>{{ $t('GENERAL.LABELS.DATE') }}</b>: {{ priceList.created_at }}
              </div>
              <template v-for="position in priceList.positions">
                <v-divider class="mt-1 mb-1"/>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ position.position_name }} - <b>{{ position.total_price }}</b>
                    </div>
                  </template>
                  <p class="mb-0 position-tooltip-row"><b>{{ position.position_name }}</b></p>
                  <template v-if="position.has_match">
                    <p class="mb-0 position-tooltip-row"><b>{{ $t('GENERAL.LABELS.OFFER') }}:</b> {{ position.offer_attributes }}</p>
                    <p class="mb-0 position-tooltip-row"><b>{{ $t('GENERAL.LABELS.INCOTERMS') }}:</b> {{ position.incoterms }}</p>
                    <p class="mb-0 position-tooltip-row"><b>{{ $t('GENERAL.LABELS.PRICE') }}:</b> {{ position.total_price }}</p>
                  </template>
                  <p class="mb-0 position-tooltip-row"><b>{{ $t('GENERAL.LABELS.MATCH_TYPE') }}:</b> {{ position.match_type }}</p>
                </v-tooltip>
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="priceLists.length">
          <v-col
            cols="12"
            class="pt-2 mb-2 d-flex justify-content-center"
          >
            <v-btn
              rounded
              color="primary"
              dark
              height="30"
              @click="handleShowOlderClick()"
            >
              {{ $t('GENERAL.LABELS.SHOW_OLDER') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script>
import {
  INIT_OPPORTUNITY_PRICE_LISTS_SEARCH,
  GET_OPPORTUNITY_PRICE_LISTS
} from "@/store/opportunityPriceLists.module";

export default {
  props: {
    entity: {
      type: Object
    },

    vimsEntity: {
      type: Object
    },

    isAppLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      priceLists: [],
      preparedPriceLists: []
    };
  },

  mounted() {
    if (this.vimsEntity.is_price_list_search_inited) {
      this.fetchPriceLists();
    }
  },

  methods: {
    handleRequestQuoteClick() {
      this.loading = true;

      this.$store.dispatch(INIT_OPPORTUNITY_PRICE_LISTS_SEARCH, this.entity.context.id)
        .then(response => {
          this.priceLists = response;
          this.preparedPriceLists = [];

          if (this.priceLists.length > 0) {
            this.preparedPriceLists.push(this.priceLists.shift());
          }

          this.vimsEntity.is_price_list_search_inited = true;
        })
        .catch(() => {
          this.$emit('error', null);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchPriceLists(sync = false) {
      this.loading = true;

      this.$store.dispatch(GET_OPPORTUNITY_PRICE_LISTS, { opportunityId: this.entity.context.id, sync: sync })
        .then(response => {
          this.priceLists = response;
          this.preparedPriceLists = [];

          if (this.priceLists.length > 0) {
            this.preparedPriceLists.push(this.priceLists.shift());
          }
        })
        .catch(() => {
          this.$emit('error', null);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleShowOlderClick() {
      this.preparedPriceLists.push(this.priceLists.shift());
    }
  }
}
</script>

<style lang="scss" scoped>
.price-lists-heading {
  font-size: 17px;
}

.price-list-date {
  font-size: 14px;
}

.price-list {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;

  // &:hover {
  //   background-color: #f5f5f5;
  // }
}

.position-tooltip-row {
  font-size: 12px
}
</style>
