<template>
	<v-container fluid>
    <v-row>
      <v-col cols="4" class="d-flex align-items-center justify-content-center pb-0">
        <h1 class="heading"><b>{{ `${$t('GENERAL.LABELS.EDIT')} ${$route.query.fieldName}` }}</b></h1>
      </v-col>
      <v-col cols="4" class="pb-0" />
      <v-col cols="4" class="pb-0">
        <v-select
          width="150px"
          v-model="order"
          :items="[
            {
              text: $t('COPPER_APPS.ORDER_LABELS.INDUSTRY_STANDARD'),
              value: 'industry-standard'
            },
            {
              text: $t('COPPER_APPS.ORDER_LABELS.ALPHABETICAL'),
              value: 'alphabetical'
            },
            {
              text: $t('COPPER_APPS.ORDER_LABELS.SELECTED_ITEMS'),
              value: 'selected-items'
            }
          ]"
          :label="$t('GENERAL.LABELS.ORDER')"
          @change="applyOrder()"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex align-items-center">
          <v-text-field
            :label="$t('GENERAL.LABELS.SEARCH')"
            @input="handleSearchInput($event)"
          />
          <v-spacer />
          <v-checkbox
            v-model="showOnlySelected"
            class="mt-0 pt-0"
            :ripple="false"
            :label="$t('GENERAL.LABELS.SHOW_ONLY_SELECTED')"
            hide-details
            @change="handleShowOnlySelectedChange($event)"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-expansion-panels multiple v-model="expansionPanels">
          <v-expansion-panel :key="`group-key-${group.id}`" v-for="group in groups">
            <template v-if="!group.hidden && !(showOnlySelected && getGroupCount(group) === 0)">
              <v-expansion-panel-header>
                <b class="group-heading">{{ group.value }} ({{ getGroupCount(group) }})</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="pb-2">
                  <v-checkbox
                    :input-value="getGroupCount(group) === $t('GENERAL.LABELS.ALL')"
                    class="all-selected mt-0 pt-0"
                    :ripple="false"
                    :label="$t('GENERAL.LABELS.SELECT_ALL_OPTION')"
                    hide-details
                    @change="handleAllChanged(group, $event)"
                  />
                </div>
                <div
                  :key="value.id"
                  v-for="value in group.values"
                >
                  <template v-if="!value.hidden && !(showOnlySelected && !value.selected)">
                    <v-divider class="mt-0 mb-0" />
                    <div class="d-flex align-items-center main-input-wrapper">
                      <v-checkbox
                        v-model="value.selected"
                        class="mt-0 pt-0"
                        :ripple="false"
                        :label="value.value"
                        hide-details
                        @change="handleValueSelected(group, value)"
                      />
                      <v-spacer />
                      <template
                        v-if="
                          value.related_fields &&
                          Array.isArray(value.related_fields) &&
                          value.related_fields.length
                        "
                      >
                        <div
                          class="ml-2 related-field-wrapper"
                          :key="`related-field-key-${relatedField.id}-value-${value.id}`"
                          v-for="relatedField in value.related_fields"
                        >
                          <template v-if="relatedFieldsStructure[relatedField.id]">
                            <v-select
                              v-if="relatedFieldsStructure[relatedField.id].input_type === 'select' && value.selected"
                              v-model="relatedField.selected"
                              :items="relatedField.options"
                              :label="relatedFieldsStructure[relatedField.id].label"
                              :error="relatedFieldsStructure[relatedField.id].required && !relatedField.selected"
                              chips
                              @change="handleRelatedFieldValueChanged(relatedField.id, $event)"
                            />
                            <v-autocomplete
                              v-if="relatedFieldsStructure[relatedField.id].input_type === 'autocomplete' && value.selected"
                              v-model="relatedField.selected"
                              :items="relatedField.options"
                              :label="relatedFieldsStructure[relatedField.id].label"
                              :error="relatedFieldsStructure[relatedField.id].required && !relatedField.selected"
                              chips
                              @change="handleRelatedFieldValueChanged(relatedField.id, $event)"
                            />
                            <MultiSelect
                              v-if="relatedFieldsStructure[relatedField.id].input_type === 'multiselect' && value.selected"
                              v-model="relatedField.selected"
                              :items="relatedField.options"
                              :label="relatedFieldsStructure[relatedField.id].label"
                              :placeholder="relatedFieldsStructure[relatedField.id].label"
                              :error="relatedFieldsStructure[relatedField.id].required && !relatedField.selected.length"
                              dense
                              @change="handleRelatedFieldValueChanged(relatedField.id, $event)"
                            />
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </v-expansion-panel-content>
            </template>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pb-0 pt-0 d-flex justify-content-center"
      >
        <v-btn
          rounded
          color="primary"
          dark
          width="150"
          class="mb-2"
          @click="updateInterests()"
        >
          {{ $t('GENERAL.LABELS.SAVE') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  GET_CLIENT_INTERESTS_GRPOUPED_FIELD_VALUES
} from "@/store/interestFields.module";

import {
  SET_CLIENT_INTERESTS_LOADING,
  UPDATE_CLIENT_INTERESTS
} from "@/store/interests.module";

import MultiSelect from "@/views/pages/copper/components/MultiSelect.vue";

export default {
  components: {
    MultiSelect
  },

  props: {
    entity: {
      type: Object
    }
  },

  data() {
    return {
      field: null,
      groups: [],
      relatedFieldsStructure: {},
      order: 'industry-standard',
      relatedFieldsLastSelectedValues: {},
      expansionPanels: [],
      showOnlySelected: false
    };
  },

  mounted() {
    this.getValues();
  },

  methods: {
    getValues() {
      this.$store.dispatch(GET_CLIENT_INTERESTS_GRPOUPED_FIELD_VALUES, {
        fieldId: this.$route.query.fieldId,
        entityTypeSlug: this.entity.type,
        entityExternalId: this.entity.context.id
      })
      .then((response) => {
        this.field = response.field;
        this.groups = response.groups;

        if (Array.isArray(response.related_fields_structure)) {
          response.related_fields_structure.forEach(field => {
            this.relatedFieldsStructure[field.id] = field;
          });
        }

        this.applyOrder();
      });
    },

    getGroupCount(group) {
      let count = group.values.filter(value => value.selected).length;

      return count !== group.values.length ? count : this.$t('GENERAL.LABELS.ALL');
    },

    handleSearchInput(search) {
      if (search.length > 2) {
        this.applySearch(search);
      } else {
        this.clearSearch();
      }
    },

    applySearch(search) {
      search = search.toLowerCase();
      this.groups.forEach((group, index) => {
        let isGroupHidden = true;

        group.values.forEach(value => {
          value.hidden = !value.value.toLowerCase().includes(search);
          if (!value.hidden) {
            isGroupHidden = false;
          }
        });

        group.hidden = isGroupHidden;

        if (!group.hidden && !this.expansionPanels.includes(index)) {
          this.expansionPanels.push(index);
        }
      });

      this.$forceUpdate();
    },

    clearSearch() {
      this.groups.forEach(group => {
        group.hidden = false;

        group.values.forEach(value => {
          value.hidden = false;
        });
      });

      this.$forceUpdate();
    },

    handleShowOnlySelectedChange(value) {
      if (!value) {
        return;
      }

      this.groups.forEach((group, index) => {
        if (this.getGroupCount(group) !== 0 && !this.expansionPanels.includes(index)) {
          this.expansionPanels.push(index);
        }
      });
    },

    handleAllChanged(group, selected) {
      group.values.forEach(value => {
        value.selected = selected;
      });
    },

    handleRelatedFieldValueChanged(fieldId, value) {
      this.relatedFieldsLastSelectedValues[fieldId] = value;
    },

    handleValueSelected(group, value) {
      this.applyOrder(group);
      this.applyRelatedFieldsSelections(value);
    },

    applyRelatedFieldsSelections(value) {
      //If we have previous selections on related field apply them here but first check
      //is selected values exists in field options because of filtered options
      if (
        value.related_fields &&
        Array.isArray(value.related_fields) &&
        value.related_fields.length
      ) {
        value.related_fields.forEach(relatedField => {
          if (this.relatedFieldsLastSelectedValues.hasOwnProperty(relatedField.id)) {
            let lastSelectedValue = [...this.relatedFieldsLastSelectedValues[relatedField.id]];

            if (Array.isArray(lastSelectedValue)) {
              let newSelectedValues = [];

              lastSelectedValue.forEach(value => {
                if (this.isExistsValueInRelatedFieldOptions(value, relatedField.options)) {
                  newSelectedValues.push(value);
                }
              });

              relatedField.selected = newSelectedValues;
            } else {
              if (this.isExistsValueInRelatedFieldOptions(lastSelectedValue, relatedField.options)) {
                relatedField.selected = lastSelectedValue;
              }
            }
          }
        });
      }
    },

    isExistsValueInRelatedFieldOptions(value, options) {
      let isInOptions = false;

      options.forEach(option => {
        if (option.value === value) {
          isInOptions = true;
        }
      });

      return isInOptions;
    },

    updateInterests() {
      if (!this.groups.length) {
        return;
      }

      this.$store.commit(SET_CLIENT_INTERESTS_LOADING, true);

      let interests = [
        {
          mapping_id: this.field.mapping_group_id,
          values: []
        },
        {
          mapping_id: this.field.mapping_id,
          values: []
        }
      ];

      let hasRelatedFields = Object.values(this.relatedFieldsStructure).length > 0;

      this.groups.forEach(group => {
        //If we doesnt have related fields and group is full selected we will save it as 1 record for interest
        if (!hasRelatedFields && this.getGroupCount(group) === this.$t('GENERAL.LABELS.ALL')) {
          interests[0].values.push({ mapping_value_id: group.id });
          return;
        }

        group.values.forEach(groupValue => {
          if (!groupValue.selected) {
            return;
          }

          let insertObject = {
            mapping_value_id: groupValue.id
          };

          if (
            groupValue.related_fields &&
            Array.isArray(groupValue.related_fields) &&
            groupValue.related_fields.length
          ) {
            insertObject.related_values = [];
            groupValue.related_fields.forEach(relatedField => {
              if (!relatedField.selected) {
                return;
              }

              if (Array.isArray(relatedField.selected)) {
                relatedField.selected.forEach(selectedValue => {
                  insertObject.related_values.push({ mapping_value_id: selectedValue });
                })
                return;
              }

              insertObject.related_values.push({ mapping_value_id: relatedField.selected });
            });

            if (!insertObject.related_values.length) {
              insertObject.related_values = undefined;
            }
          }

          interests[1].values.push(insertObject);
        })
      });

      this.$store.dispatch(UPDATE_CLIENT_INTERESTS, {
        entityTypeSlug: this.entity.type,
        entityExternalId: this.entity.context.id,
        interests: interests
      }).then(() => {
        this.$emit('saved');
      }).finally(() => {
        this.$store.commit(SET_CLIENT_INTERESTS_LOADING, false);
      });
    },

    applyOrder(group = null) {
      if (group !== null) {
        if (this.order === 'selected-items') {
          this.orderGroup(group);
        }

        return;
      }

      this.groups.forEach(group => {
        this.orderGroup(group);
      });
    },

    orderGroup(group) {
      let sortCallback = null;

      if (this.order === 'alphabetical') {
        sortCallback = (a, b) => {
          if (a.value < b.value) {
            return -1;
          }

          if (a.value > b.value) {
            return 1;
          }

          return 0;
        };
      } else if (this.order === 'industry-standard') {
        sortCallback = (a, b) => {
          if (a.order < b.order) {
            return -1;
          }

          if (a.order > b.order) {
            return 1;
          }

          return 0;
        };
      } else if (this.order === 'selected-items') {
        sortCallback = (a, b) => {
          if (a.selected && !b.selected) {
            return -1;
          }

          return 0;
        };
      }

      group.values.sort(sortCallback);
    }
  }
}
</script>

<style lang="scss" scoped>
.group-heading {
  font-size: 14px;
}

:deep(label) {
  margin-bottom: 0;
}

.all-selected :deep(label) {
  font-weight: bold;
}

.main-input-wrapper {
  min-height: 58px;
}

.related-field-wrapper {
  max-width: 150px;

  :deep(.v-input__slot) {
    margin-bottom: 0;
  }

  :deep(.v-text-field__details) {
    display: none;
  }
}
</style>
