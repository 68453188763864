import Sortable from "sortablejs";
import userStateService from "@/common/userState.service.js";

export default {
  methods: {
    initReorderableColumns() {
      const el = this.$refs.table.$el;

      el.querySelectorAll("th").forEach((draggableEl) => {
        if (draggableEl.classList.contains('select-column') || draggableEl.classList.contains('options-column')) {
          return;
        }

        // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
        let lastClassState = draggableEl.classList.contains('sortHandle');
        const observer = new MutationObserver((mutationsList) => {
          for (let i = 0; i < mutationsList.length; i++) {
            const mutation = mutationsList[i];
            if (
              mutation.type === "attributes" &&
              mutation.attributeName === "class"
            ) {
              const currentClassState = mutation.target.classList.contains(
                'sortHandle'
              );
              if (lastClassState !== currentClassState) {
                lastClassState = currentClassState;
                if (!currentClassState) {
                  mutation.target.classList.add("sortHandle");
                }
              }
            }
          }
        });

        observer.observe(draggableEl, { attributes: true });

        draggableEl.classList.add("sortHandle");
      });

      Sortable.create(
        el.querySelector("tr"),
        { onEnd: this.handleReorderHeaders, handle: ".sortHandle" }
      );
    },

    handleReorderHeaders(event) {
      let tempHeaders = [...this.localHeaders];
      tempHeaders[event.newIndex] = {...this.localHeaders[event.oldIndex]};
      tempHeaders[event.oldIndex] = {...this.localHeaders[event.newIndex]};

      let headersSort = {};
      tempHeaders.forEach((header, index) => {
        headersSort[header.value] = index + 1;
      });

      userStateService.setState(
        `table-state.${this.name}.headersSort`,
        headersSort
      );

      this.localHeaders = this.sortHeaders(tempHeaders);

      this.rerenderTable();
    },

    sortHeaders(headers) {
      let headersSort = userStateService.getState(`table-state.${this.name}.headersSort`, {});

      headers.sort((a, b) => {
        if (b.value === 'select') return 1;
        if (a.value === 'select') return -1;

        if (b.value === 'options') return -1;
        if (a.value === 'options') return 1;

        if (headersSort[a.value] && headersSort[b.value]) {
          return headersSort[a.value] < headersSort[b.value] ? -1 : 1;
        }

        return 0;
      });

      return headers;
    },
  }
};