<template>
  <div>
    <div class="d-flex" v-if="itemData.header.editOptions && isEditMode">
      <v-text-field
        v-if="isText()"
        ref="text-input"
        solo
        dense
        :value="itemData.item[itemData.header.editOptions.model]"
        @input="handleValueChange($event)"
        @keydown.esc="handleCancelClick()"
        @keydown.enter="handleSaveClick()"
      ></v-text-field>
      <v-autocomplete
        v-else-if="isAutocomplete()"
        solo
        dense
        :return-object="itemData.header.editOptions['return-object'] === true"
        :value="itemData.item[itemData.header.editOptions.model]"
        :items="itemData.header.editOptions.items"
        :item-value="itemData.header.editOptions['item-value']"
        :item-text="itemData.header.editOptions['item-text']"
        @change="handleValueChange($event)"
        @keydown.esc="handleCancelClick()"
        @keydown.enter="handleSaveClick()"
      />
      <v-icon
        color="green"
        class="ml-3 mr-3 set-cursor-pointer"
        @click.native="handleSaveClick()"
      >
        mdi-check
      </v-icon>
      <v-icon
        color="red"
        class="set-cursor-pointer"
        @click.native="handleCancelClick()"
      >
        mdi-close
      </v-icon>
    </div>
    <div
      v-else
      :class="{ 'set-cursor-pointer': itemData.header.editOptions }"
      @click="handleItemClick()"
    >
    {{ (itemData.header?.value)
      ? handleDisplayValue(itemData.header.value, { [itemData.header.value]: itemData.value})
      : itemData.value
    }}
    </div>
  </div>
</template>

<script>
import formatter from "@/common/formatter.service";

export default {
  props: {
    itemData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isEditMode: false,
      value: null
    }
  },

  mounted() {
    //
  },

  methods: {
    handleItemClick() {
      if (this.isEditable()) {
        this.isEditMode = true;

        if (this.isText()) {
          this.$nextTick(() => {
            this.$refs['text-input'].focus();
          });
        }
      }
    },

    isEditable() {
      return this.itemData.header.editOptions &&
        this.itemData.header.editOptions.model;
    },

    isText() {
      return this.isEditable() &&
        this.itemData.header.editOptions.type === 'text';
    },

    isAutocomplete() {
      return this.isEditable() &&
        this.itemData.header.editOptions.type === 'autocomplete' &&
        this.itemData.header.editOptions.items &&
        this.itemData.header.editOptions.items.length &&
        this.itemData.header.editOptions['item-text'] &&
        this.itemData.header.editOptions['item-value'];
    },

    handleValueChange(item) {
      this.value = item;
    },

    handleSaveClick() {
      this.isEditMode = false;

      if (this.value) {
        this.itemData.item[this.itemData.header.editOptions.model] = this.value;

        this.$emit('item-updated', this.itemData.item);
      }
    },

    handleCancelClick() {
      this.value = null;
      this.isEditMode = false;
    },

    handleDisplayValue(itemName, itemObj) {
      return formatter.handleDisplayValue(itemName, itemObj);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field__details) {
  display: none;
}
::v-deep(.v-input__slot) {
  margin-bottom: 0 !important;
}
</style>