<template>
	<div>
    <template v-if="entity && entitySynced">
      <EditGrouped
        v-if="$route.query.action === 'editGroupedMappingField'"
        :entity="entity"
        @saved="handleEditSaved()"
      />
      <CloneInterests
        v-else-if="$route.query.action === 'cloneInterests'"
        :entity="entity"
        @saved="handleCloneInterestsSaved()"
      />
      <ShowPriceList
        v-else-if="$route.query.action === 'showPriceList'"
        :entity="entity"
        :vimsEntity="vimsEntity"
        @close="handleShowPriceListClose()"
      />
      <Main
        v-else
        :entity="entity"
        :vimsEntity="vimsEntity"
        :isAppLoading="isAppLoading"
        @edit-grouped-mapping-field-clicked="
          handleEditGroupedMappingFieldClicked($event.fieldId, $event.fieldName)
        "
        @clone-interests-clicked="handleCloneInterestsClicked()"
        @price-list-clicked="handlePriceListClicked($event.priceListId)"
      />
    </template>
    <v-overlay
      :absolute="true"
      :value="isAppLoading || error"
    >
      <v-progress-circular
        v-if="!error"
        :size="50"
        indeterminate
        color="primary"
      />
      <v-alert
        prominent
        type="error"
        v-else
      >
        <v-row align="center">
          <v-col class="grow">
            {{ $t('GENERAL.UNEXPECTED_ERROR') }}
          </v-col>
          <v-col class="shrink">
            <v-btn color="primary" @click="handleReloadPageClick()">{{ $t('GENERAL.LABELS.RELOAD_PAGE') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SYNC_COPPER_ENTITY } from "@/store/copperEntities.module";
import EditGrouped from "@/views/pages/copper/components/EditGrouped.vue";
import Main from "@/views/pages/copper/components/Main.vue";
import CloneInterests from "@/views/pages/copper/components/CloneInterests.vue";
import ShowPriceList from "@/views/pages/copper/components/ShowPriceList.vue";
import CopperContextService from '@/common/copper-context.service';

export default {
  components: {
    EditGrouped,
    Main,
    CloneInterests,
    ShowPriceList
  },

  data() {
    return {
      entity: null,
      entitySynced: false,
      vimsEntity: null,
      error: false
    };
  },

  computed: {
    ...mapGetters([
      'copperEntityLoading',
      'clientInterestsLoading',
      'clientInterestFieldsLoading',
      'offerGroupsLoading',
      'incotermsLoading',
      'opportunityPriceListsLoading'
    ]),

    isAppLoading() {
      return this.copperEntityLoading
        || this.clientInterestsLoading
        || this.clientInterestFieldsLoading
        || this.offerGroupsLoading
        || this.incotermsLoading
        || this.opportunityPriceListsLoading;
    }
  },

	async mounted() {
    this.entity = await CopperContextService.getCurrentEntity();

    this.$store.dispatch(SYNC_COPPER_ENTITY, {
      entityTypeSlug: this.entity.type,
      entityExternalId: this.entity.context.id
    })
    .then(response => {
      this.vimsEntity = response;
      this.entitySynced = true;
    })
    .catch(() => {
      this.error = true;
    });
  },

  methods: {
    handleEditSaved() {
      CopperContextService.closeModalApplication();
      CopperContextService.refreshUI();
    },

    handleShowPriceListClose() {
      CopperContextService.closeModalApplication();
    },

    handleCloneInterestsSaved() {
      CopperContextService.closeModalApplication();
    },

    handleEditGroupedMappingFieldClicked(fieldId, fieldName) {
      CopperContextService.openModalApplication({
        action: 'editGroupedMappingField',
        fieldId: fieldId,
        fieldName: fieldName
      });
    },

    handlePriceListClicked(priceListId) {
      CopperContextService.openModalApplication({
        action: 'showPriceList',
        priceListId: priceListId
      }, '80%');
    },

    handleCloneInterestsClicked() {
      CopperContextService.openModalApplication({ action: 'cloneInterests' });
    },

    handleReloadPageClick() {
      window.location.reload();
    }
  }
}
</script>
