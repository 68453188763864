<template>
  <v-card :loading="loading || serverLoading">
    <TopHeader
      v-if="headersPrepared"
      :filterable="filterable"
      :filters="filters"
      :filters-values="serverFilters || filtersValues"
      :search-value="searchString || searchValue"
      :hidden-headers="hiddenHeaders"
      :hidden-headers-values="hiddenHeadersValues"
      @search="
        serverHandleSearchInput($event, options);
        $emit('search', $event);
      "
      @filters-change="
        serverHandleFiltersChange($event, options);
        $emit('filters-change', $event);
      "
      @headers-change="handleHeadersChange"
    >
      <template v-slot:top-header-actions>
        <slot name="top-header-actions"></slot>
      </template>
    </TopHeader>
    <v-data-table
      ref="table"
      height="500"
      :class="{ 'stripped': stripped }"
      :key="tableKey"
      v-bind="$attrs"
      fixed-header
      :headers="localHeaders"
      :options.sync="options"
      :items="serverItems.length ? serverItems : items"
      :server-items-length="localTotalItems"
      :items-per-page="itemsPerPage"
      :item-class="rowClass"
      mobile-breakpoint="0"
      :hide-default-footer="true"
      :item-key="itemKey"
    >
      <template v-for="slot in headersSlots" v-slot:[`header.${slot}`]="{ header }">
        <slot :name="`header.${slot}`" :header="header">
          <div class="text-center">
            {{ header.text }}
          </div>
        </slot>
      </template>
      <template v-slot:item="item">
        <slot name="item" :item="item">
          <tr :class="rowClass(item.item)">
            <td
              v-for="(header, index) in item.headers"
              :class="{
                'text-center': header.align === 'center',
                'p-0': header.padding === false
              }"
            >
              <v-checkbox
                v-if="selectable && header.value === 'select'"
                hide-details
                class="select-checkbox"
                :input-value="item.item.selected"
                @change="handleItemSelected(item.item, $event)"
              />
              <slot
                v-else
                :name="`item.${header.value}`"
                :item="{
                  item: item.item,
                  header: header,
                  value: getItemValue(item.item, header.value),
                  index: index
                }"
              >
                <Item
                  :key="`item-key-${header.value}-${index}`"
                  :itemData="{
                    item: item.item,
                    header: header,
                    value: getItemValue(item.item, header.value),
                    index: index
                  }"
                  @item-updated="handleItemUpdated"
                />
              </slot>
            </td>
          </tr>
        </slot>
      </template>
      <template v-slot:footer="{ props }">
        <div class="table-footer d-flex">
          <v-spacer />
          <div class="table-footer-items">
            {{ `${$t('GENERAL.TABLE.TOTAL_ITEMS')}: ${props.pagination.itemsLength}` }}
          </div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { get } from "lodash";
import userStateService from "@/common/userState.service.js";
import Item from "@/views/partials/v-table/components/Item.vue"
import TopHeader from "@/views/partials/v-table/components/TopHeader.vue"
import blinkRowMixin from "@/views/partials/v-table/mixins/blinkRow.mixin";
import serverFetchingMixin from "@/views/partials/v-table/mixins/serverFetching.mixin";
import reorderColumnsMixin from "@/views/partials/v-table/mixins/reorderColumns.mixin";
import resizableColumnsMixin from "@/views/partials/v-table/mixins/resizableColumns.mixin";

export default {
  mixins: [
    blinkRowMixin,
    serverFetchingMixin,
    resizableColumnsMixin,
    reorderColumnsMixin
  ],

  components: {
    Item,
    TopHeader
  },

  props: {
    name: {
      type: String,
      required: true
    },

    headers: {
      type: Array,
      default: undefined
    },

    headersSlots: {
      type: Array,
      default: () => []
    },

    hiddenHeaders: {
      type: Array,
      default: undefined
    },

    items: {
      type: Array,
      default: undefined
    },

    totalItems: {
      type: Number,
      default: 0
    },

    itemsPerPage: {
      type: Number,
      default: 50
    },

    filterable: {
      type: Boolean,
      default: false
    },

    filters: {
      type: Array,
      default: () => []
    },

    filtersValues: {
      type: Object,
      default: () => ({})
    },

    searchValue: {
      type: String,
      default: ''
    },

    loading: {
      type: Boolean,
      default: false
    },

    itemKey: {
      type: String,
      default: 'id'
    },

    selectable: {
      type: Boolean,
      default: false
    },

    stripped: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      tableKey: 1,
      options: {},
      localHeaders: [],
      hiddenHeadersValues: [],
      headersPrepared: false,
      localTotalItems: this.totalItems,
    }
  },

  mounted() {
    this.prepareHeaders();

    this.$refs.table.$el
      .querySelector('.v-data-table__wrapper')
      .addEventListener('scroll', this.handleTableScroll);
  },

  beforeDestroy() {
    this.$refs.table.$el
      .querySelector('.v-data-table__wrapper')
      .removeEventListener('scroll', this.handleTableScroll);
  },

  watch: {
    options: {
      handler (newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.serverHandleOptionsUpdate(this.options);
          this.$emit('update:options', this.options);
        }
      },
      deep: true
    },

    totalItems() {
      this.localTotalItems = this.totalItems;
    },
  },

  methods: {
    prepareHeaders() {
      this.hiddenHeadersValues = userStateService.getState(`table-state.${this.name}.selectedHiddenHeaders`, []);

      let headers = [];
      if (this.selectable) {
        headers.push({
          text: '',
          value: 'select',
          sortable: false,
          align: 'center',
          class: 'select-column'
        });
      }

      this.localHeaders = this.sortHeaders([...headers, ...this.headers, ...this.hiddenHeadersValues]);

      this.headersPrepared = true;

      this.initTableBehaviors();
    },

    handleItemUpdated(item) {
      this.$emit('item-updated', item);
    },

    getItemValue(item, key) {
      return get(item, key);
    },

    rowClass(item) {
      return `row-item-key-${item[this.itemKey]}`;
    },

    rerenderTable() {
      const scrollTop = this.$refs.table.$el
        .querySelector('.v-data-table__wrapper')
        .scrollTop;

      this.tableKey++;

      this.initTableBehaviors();

      this.$nextTick(() => {
        this.$refs.table.$el
          .querySelector('.v-data-table__wrapper')
          .scrollTop = scrollTop;

        this.$refs.table.$el
          .querySelector('.v-data-table__wrapper')
          .addEventListener('scroll', this.handleTableScroll);
      });
    },

    initTableBehaviors() {
      this.$nextTick(() => {
        this.initResizableColumns(true);
        this.updateColumnsSize();
        this.initReorderableColumns();
      });
    },

    handleTableScroll() {
      if (this.isServerFetchingNow) {
        return;
      }

      const scrolledEl = this.$refs.table.$el
        .querySelector('.v-data-table__wrapper');

      const threshold = 300;

      if (scrolledEl.scrollTop < threshold && this.fetchedPages[0] > 1) {
        this.$emit('scroll-to-top');

        if (this.isServerFetching()) {
          this.serverHandleScrollToTop();
        }
      }

      const scrollBottom = scrolledEl.scrollHeight - (scrolledEl.scrollTop + scrolledEl.offsetHeight);

      if (scrollBottom < threshold && this.fetchedPages[this.fetchedPages.length - 1] < this.lastPage) {
        this.$emit('scroll-to-bottom');

        if (this.isServerFetching()) {
          this.serverHandleScrollToBottom();
        }
      }
    },

    handleHeadersChange(headers) {
      userStateService.setState(
        `table-state.${this.name}.selectedHiddenHeaders`,
        headers
      );

      let tmpHeaders = [];
      if (this.selectable) {
        tmpHeaders.push({
          text: '',
          value: 'select',
          sortable: false,
          align: 'center',
          class: 'select-column'
        });
      }

      this.localHeaders = this.sortHeaders([...tmpHeaders, ...this.headers, ...headers]);

      this.initTableBehaviors();
    },

    clearSorting() {
      this.options.sortBy = [];
      this.options.sortDesc = [false];

      this.serverHandleOptionsUpdate(this.options);
      this.$emit('update:options', this.options);
    },

    handleItemSelected(item, event) {
      this.$emit('item-selected', item);
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(table) {
  // overflow: hidden;

  td, th {
    vertical-align: inherit !important;
  }

  th {
    position: sticky !important;
    top: 0 !important;
  }

  .select-checkbox {
    margin: 0 !important;
  }
}

:deep(.select-checkbox .v-input--selection-controls__input) {
  margin-right: 0 !important;
}

:deep(.select-column) {
  padding: 0 !important;
}

.stripped tr:nth-child(even) {
  background-color: #f8f8f8;
}

:deep(#indicators) {
  padding: 0 4px;
  min-width: 40px;
}

:deep(.blink-row) {
  animation: blinker 0.7s linear 3;
}

@keyframes blinker {
  50% {
    background: #00ca19;
  }
}

.table-footer {
  padding: 16px;
  font-size: 12px;
  color: #757575;
}
</style>