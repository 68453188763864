<template>
    <div>
        <v-autocomplete
            v-bind="$attrs"
            v-model="model"
            v-bind:value="value"
            v-on:input="handleInput"
            :items="items"
            :loading="isItemLoading"
            :search-input.sync="searchValue"
            :item-text="formatText"
            return-object
            @click="$emit('click')"
            @blur="$emit('blur')"
            @change="$emit('change', $event)"
        >
            <template v-slot:append-item>
                <slot name="append-item"></slot>
            </template>
            <template v-if="itemSlot" v-slot:item="{ on, attrs, item}">
                <slot
                    name="item"
                    v-bind:attrs="attrs"
                    v-bind:item="item"
                    v-on="on"
                ></slot>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import { debounce } from "lodash";

export default {
    inheritAttrs: false,
    props: {
        value: [String, Object, Number],
        getter: String,
        items: Array,
        displayFields: Array,
        defaults: Object,
        itemSlot: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: null,
            isItemLoading: false,
            searchValue: null,
            getDefault:true
        };
    },
    watch: {
        searchValue() {
            this.debouncedSearch();
        },
        value(v) {
            if(this.defaults && this.getDefault){
                 this.items.push(this.defaults);
                 this.model =this.defaults;
                 this.getDefault = false;
                }else{
                    this.model = this.items.find(i => i.id === v);
                }
        }
    },
    mounted() {
        if (this.value) {
          if(this.defaults && this.getDefault){
            this.items.push(this.defaults);
            this.model =this.defaults;
            this.getDefault = false;
          }else{
              this.model = this.items.find(i => i.id === this.value);
          }
        }
    },
    methods: {
        search() {
            if (
                !this.searchValue ||
                this.searchValue.length < 2 ||
                this.searchValue === this.formatText(this.model)
            ) {
                this.isItemLoading = false;
                return;
            }
            this.isItemLoading = true;
            this.$store.dispatch(this.getter, this.searchValue).finally(() => {
                this.$emit("loaded");
                this.isItemLoading = false;
            });
        },
        debouncedSearch: debounce(function() {
            this.search();
        }, 500),
        formatText(i) {
            if (!i) {
                return;
            }

            var displayArray = [];
            this.displayFields.forEach(field => {
                if (i[field]) {
                    displayArray.push(i[field]);
                }
            });
            return displayArray.join(", ");
        },
        handleInput() {
            this.$emit('input', this.model?.id);

            if (this.model?.id === undefined) {
                this.$nextTick(() => {
                    this.model = null
                });
            }
        }
    }
};
</script>
