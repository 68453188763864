<template>
	<div>
    <v-row v-if="this.entity.type === 'person'">
      <v-col
        cols="12"
        class="pb-0 pt-0"
      >
        <v-select
          v-model="entityOfferGroup"
          :items="offerGroups"
          item-value="key"
          item-text="name"
          :label="$t('GENERAL.LABELS.OFFER_GROUP')"
          @change="updateEntityOfferGroup($event)"
        />
      </v-col>
    </v-row>
    <template v-if="['person', 'opportunity'].includes(this.entity.type)">
      <v-row>
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-select
            v-model="entityIncoterm"
            :items="incoterms"
            item-value="key"
            item-text="name"
            :label="$t('GENERAL.LABELS.INCOTERMS')"
            @change="updateEntityIncoterm($event)"
          />
        </v-col>
      </v-row>
      <v-row v-if="entityIncotermObject?.include_transport">
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-select
            v-model="entityCountry"
            :items="countries"
            item-value="key"
            item-text="name"
            :error="!entityCountry"
            :label="$t('GENERAL.LABELS.DESTINATION_COUNTRY')"
            @change="updateEntityCountry($event)"
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="fields">
      <v-row :key="field.id" v-for="field in fields">
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-select
            v-if="field.input_type === 'select'"
            v-model="field.selected"
            clearable
            :items="field.options"
            :label="field.label"
            :error="field.required && !field.selected"
            @change="handleValueChanged(field, $event)"
          />
          <v-autocomplete
            v-if="field.input_type === 'autocomplete'"
            v-model="field.selected"
            clearable
            :items="field.options"
            :label="field.label"
            :error="field.required && !field.selected"
            @change="handleValueChanged(field, $event)"
          />
          <MultiSelect
            v-if="field.input_type === 'multiselect'"
            v-model="field.selected"
            :items="field.options"
            :label="field.label"
            :placeholder="field.label"
            :error="field.required && !field.selected.length"
            @change="handleValueChanged(field, $event)"
          />
          <template v-if="field.input_type === 'grouped-mapping'">
            <div class="d-flex align-items-center pt-3 pb-3">
              <h1 class="heading mb-0">{{ field.label }}</h1>
              <v-icon v-if="field.required && (!field.groups_counts || !Object.values(field.groups_counts).length)" color="red">info</v-icon>
              <v-spacer />
              <v-btn
                rounded
                color="primary"
                dark
                height="30"
                @click="$emit('edit-grouped-mapping-field-clicked', { fieldId: field.id, fieldName: field.label })"
              >
                {{ $t('GENERAL.LABELS.EDIT_BUTTON') }}
              </v-btn>
            </div>
            <template v-if="field.groups_counts">
              <div :key="groupName" v-for="(count, groupName) in field.groups_counts">
                <v-divider class="mt-2 mb-2"/>
                <div class="d-flex">
                  <p class="mb-0">{{ groupName }}</p>
                  <v-spacer />
                  <p class="mb-0">{{ count }}</p>
                </div>
              </div>
              <v-divider class="mt-2 mb-4"/>
            </template>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="this.entity.type === 'person'">
        <v-col
          cols="12"
          class="pt-0 d-flex justify-content-center"
        >
          <v-btn
            rounded
            color="primary"
            dark
            height="30"
            @click="$emit('clone-interests-clicked')"
          >
            {{ $t('GENERAL.LABELS.CLONE_INTERESTS_BUTTON') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { find } from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";
import { GET_SELECTS_DATA } from "@/store/selectsData.module";
import {
  GET_ENTITY_OFFER_GROUP,
  UPDATE_ENTITY_OFFER_GROUP
} from "@/store/offerGroups.module";
import {
  GET_ENTITY_INCOTERMS,
  UPDATE_ENTITY_INCOTERM,
  UPDATE_ENTITY_COUNTRY
} from "@/store/incoterms.module";
import { GET_CLIENT_INTERESTS_FIELDS } from "@/store/interestFields.module";
import { UPDATE_CLIENT_INTERESTS } from "@/store/interests.module";
import MultiSelect from "@/views/pages/copper/components/MultiSelect.vue";

export default {
  components: {
    MultiSelect
  },

  props: {
    entity: {
      type: Object
    }
  },

  data() {
    return {
      fields: null,
      offerGroups: null,
      incoterms: null,
      countries: null,
      entityOfferGroup: null,
      entityIncoterm: null,
      entityIncotermObject: null,
      entityCountry: null
    };
  },

  mounted() {
    if (this.entity.type === 'person') {
      // Here we wait first request to be finished
      // because of country auto select
      Promise.all([
        this.getContactSelectsData(),
        this.getEntityOfferGroup()
      ]).then(() => {
        this.getEntityIncoterms();
        this.getFields();
      });

    } else if (this.entity.type === 'opportunity') {
      this.getOpportunitySelectsData();
      this.getEntityIncoterms();
      this.getFields();
    } else {
      this.getFields();
    }
  },

  methods: {
    getContactSelectsData() {
      this.$store.dispatch(GET_SELECTS_DATA, ['offer-groups', 'incoterms', 'countries'])
      .then((response) => {
        this.offerGroups = response['offer-groups'];
        this.incoterms = response['incoterms'];
        this.countries = response['countries'];
      });
    },

    getOpportunitySelectsData() {
      return this.$store.dispatch(GET_SELECTS_DATA, ['incoterms', 'countries-only-with-transport'])
        .then((response) => {
          this.incoterms = response['incoterms'];
          this.countries = response['countries-only-with-transport'];
        });
    },

    getEntityOfferGroup() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_ENTITY_OFFER_GROUP, {
          entityTypeSlug: this.entity.type,
          entityExternalId: this.entity.context.id
        })
        .then((response) => {
          this.entityOfferGroup = response.id;

          resolve();
        })
        .catch((response) => {
          this.$emit('error', response.message);

          reject();
        });
      });
    },

    getEntityIncoterms() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_ENTITY_INCOTERMS, {
          entityTypeSlug: this.entity.type,
          entityExternalId: this.entity.context.id
        })
        .then((response) => {
          if (response.incoterm) {
            this.entityIncoterm = response.incoterm.id;
            this.entityIncotermObject = response.incoterm;
          }

          if (response.country) {
            this.entityCountry = response.country.id;
          } else if (this.entity.type === 'person') {
            this.updateCountriesFields();
          }

          resolve();
        })
        .catch((response) => {
          this.$emit('error', response.message);

          reject();
        });
      });
    },

    updateEntityOfferGroup(value) {
      this.$store.dispatch(UPDATE_ENTITY_OFFER_GROUP, {
        entityTypeSlug: this.entity.type,
        entityExternalId: this.entity.context.id,
        offer_group_id: value
      }).then(() => {
        this.$emit('entity-data-updated');

        this.$store.dispatch(SHOW_SNACK, {
          message: this.$t('GENERAL.SUCCESS_UPDATE_MESSAGE'),
          timeout: 1500
        });
      }).catch(() => {
        this.$emit('error', null);
      });
    },

    updateEntityIncoterm(value) {
      this.$store.dispatch(UPDATE_ENTITY_INCOTERM, {
        entityTypeSlug: this.entity.type,
        entityExternalId: this.entity.context.id,
        incoterm_id: value
      }).then((response) => {
        this.entityIncotermObject = response;

        this.$emit('entity-data-updated');

        this.$store.dispatch(SHOW_SNACK, {
          message: this.$t('GENERAL.SUCCESS_UPDATE_MESSAGE'),
          timeout: 1500
        });
      }).catch(() => {
        this.$emit('error', null);
      });
    },

    updateEntityCountry(value) {
      return this.$store.dispatch(UPDATE_ENTITY_COUNTRY, {
        entityTypeSlug: this.entity.type,
        entityExternalId: this.entity.context.id,
        country_id: value
      }).then(() => {
        this.$emit('entity-data-updated');

        this.$store.dispatch(SHOW_SNACK, {
          message: this.$t('GENERAL.SUCCESS_UPDATE_MESSAGE'),
          timeout: 1500
        });
      }).catch(() => {
        this.$emit('error', null);
      });
    },

    getFields() {
      this.$store.dispatch(GET_CLIENT_INTERESTS_FIELDS, {
        entityTypeSlug: this.entity.type,
        entityExternalId: this.entity.context.id
      })
      .then((response) => {
        this.fields = response;
      })
      .catch((response) => {
        this.$emit('error', response.message);
      });
    },

    updateCountriesFields() {
      if (!this.entity.context.address || !this.entity.context.address.country) {
        return;
      }

      let entityCountryOption = find(
        this.countries,
        option => option.name.endsWith('- ' + this.entity.context.address.country)
      );

      if (!entityCountryOption || entityCountry) {
        return;
      }

      this.updateEntityCountry(entityCountryOption.key).then(() => {
        this.entityCountry = entityCountryOption.key;
      });
    },

    handleValueChanged(field, selected) {
      let values = [];
      if (Array.isArray(selected)) {
        selected.forEach(value => {
          values.push({ mapping_value_id: value });
        });
      } else if (selected) {
        values.push({ mapping_value_id: selected });
      }

      let interests = [{
        mapping_id: field.mapping_id,
        values: values
      }];

      this.updateFieldInterests(interests).then(() => {
        this.$emit('entity-data-updated');

        this.$store.dispatch(SHOW_SNACK, {
          message: this.$t('GENERAL.SUCCESS_UPDATE_MESSAGE'),
          timeout: 1500
        });
      }).catch(() => {
        this.$emit('error', null);
      });
    },

    updateFieldInterests(interests) {
      return this.$store.dispatch(UPDATE_CLIENT_INTERESTS, {
        entityTypeSlug: this.entity.type,
        entityExternalId: this.entity.context.id,
        interests: interests
      });
    },

    handleReloadPageClick() {
      window.location.reload();
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
