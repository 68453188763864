export default {
  data() {
    return {
      tableEl: undefined,
      tableColsEls: undefined,
      columnWidths: {}
    };
  },

  methods: {
    initResizableColumns(saveCurrentColumnWidths = false) {
      this.tableEl = this.$refs.table.$el.querySelectorAll('table')[0];

      let header = this.tableEl.getElementsByTagName('tr')[0];

      this.tableColsEls = header.children;

      this.prepareColumns(header.offsetHeight);

      if (!saveCurrentColumnWidths) {
        for (let i = 0; i < this.tableColsEls.length; i++) {
          this.columnWidths[this.tableColsEls[i].id] = this.tableColsEls[i].offsetWidth;
        }
      }
    },

    prepareColumns(headerHeight) {
      for (let i = 0; i < this.tableColsEls.length; i++) {
        let div = this.createResizeDiv(headerHeight);

        this.tableColsEls[i].appendChild(div);
        this.tableColsEls[i].style.position = 'relative';
        this.tableColsEls[i].id = this.localHeaders[i].value;

        this.setListeners(div);
      }
    },

    createResizeDiv(headerHeight) {
      let div = document.createElement('div');

      div.style.top = 0;
      div.style.right = 0;
      div.style.width = '5px';
      div.style.position = 'absolute';
      div.style.cursor = 'col-resize';
      div.style.userSelect = 'none';
      div.style.height = headerHeight + 'px';

      return div;
    },

    setListeners(div) {
      let pageX, curCol, curColWidth, tableWidth;

      div.addEventListener('click', event => {
        event.stopPropagation();
      });

      div.addEventListener('mousedown', event => {
        tableWidth = this.tableEl.offsetWidth;
        curCol = event.target.parentElement;
        pageX = event.pageX;

        let padding = this.getColumnPaddingDiff(curCol);

        curColWidth = curCol.offsetWidth - padding;
      });

      div.addEventListener('mouseover', event => {
        event.target.style.borderRight = '2px solid #e0e0e0';
        event.target.style.height = this.tableEl.offsetHeight + 'px';
      })

      div.addEventListener('mouseout', event => {
        event.target.style.borderRight = '';
      })

      document.addEventListener('mousemove', event => {
        if (curCol) {
          event.preventDefault();

          let diffX = event.pageX - pageX;

          let newWidth = curColWidth + diffX;

          curCol.style.width = newWidth + 'px';

          this.tableEl.style.width = (tableWidth + diffX) + 'px';

          this.columnWidths[curCol.id] = newWidth;
        }
      });

      document.addEventListener('mouseup', () => {
        curCol = undefined;
        pageX = undefined;
        curColWidth = undefined;
      });
    },

    getColumnPaddingDiff(col) {
      if (this.getStyleVal(col, 'box-sizing') == 'border-box') {
        return 0;
      }

      let padLeft = this.getStyleVal(col, 'padding-left');
      let padRight = this.getStyleVal(col, 'padding-right');

      return (parseInt(padLeft) + parseInt(padRight));
    },

    getStyleVal(element, css){
      return (window.getComputedStyle(element, null).getPropertyValue(css));
    },

    updateColumnsSize() {
      let newTableWidth = -17;

      for (let i = 0; i < this.tableColsEls.length; i++) {
        let newColumnWidth = this.columnWidths[this.tableColsEls[i].id];

        this.tableColsEls[i].style.width = newColumnWidth + 'px';

        newTableWidth += newColumnWidth;
      }

      this.tableEl.style.width = newTableWidth + 'px';
    }
  }
};