const replaceAt = (value, index, replacement) => {
    return value.replace(value.substring(index,index+1),replacement)
};

const numberFormatter = val => {
    val = val.replace(/[^0-9.,]/,'');

    // Check if separator on thousands is , by check if exist . and , than replace with ''
    if([',','.'].every(item => val.includes(item))){
        val = val.replaceAll(",", "");
    }
    // Check if there is comma on thousand
    const commaIndex = val.length - 4;

    if (val[commaIndex] === ",") {
        val = replaceAt(val, commaIndex, "");
    }

    // Remove all empty strings
    val = val.replaceAll(" ", "").replaceAll(",", ".");

    return parseFloat(val);
};

const valueFormatter = (itemName, itemObj) => {
    //console.log(`print: ${itemName}`);
  
    switch (itemName) {
      case 'alias_5':
      case 'normalize_data_4':
      case 'price':
      case 'indicative_price':
      case 'cost_base':
        return Number(itemObj[itemName]).toFixed(2);
      case 'quantity':
        return Number(itemObj[itemName]).toLocaleString();
      default:
        return itemObj[itemName];
    }
};

export default {
    formatBasedOnType(value, type) {
        let formatted = value;
        if (type === "numeric") {
            formatted = numberFormatter(value);
        }
        return formatted;
    },
    handleDisplayValue(itemName, itemObj) {
        return valueFormatter(itemName, itemObj);
    }
};