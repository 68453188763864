<template>
    <v-list-item @click="addNewContact">
        <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>
                Add New Contact
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import { GET_LATEST_SYNC_CONTACT } from "@/store/companyContact.module";
import { COPPER_NEW_CONTACT } from "@/common/config";
import { SHOW_SNACK } from "@/store/snack.module";

export default {
    props: {
        showPanel: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            //
        };
    },
    methods: {
        addNewContact() {
            this.$emit("click");
            var w = 800;
            var h = 600;
            var left = screen.width / 2 - w / 2;
            var top = screen.height / 2 - h / 2;
            var copperWindow = window.open(
                COPPER_NEW_CONTACT,
                "copperNewContact",
                "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
                    w +
                    ", height=" +
                    h +
                    ", top=" +
                    top +
                    ", left=" +
                    left
            );

            this.$store.dispatch(SHOW_SNACK, {
                message:
                    "Please close the popup window after adding new contact",
                type: "info",
                timeout: 100000
            });
            var timer = setInterval(() => {
                if (!copperWindow.closed || !this.showPanel) {
                    return;
                }

                clearInterval(timer);
                this.$emit("processing");

                setTimeout(() => {
                    this.$store
                        .dispatch(GET_LATEST_SYNC_CONTACT)
                        .then(contact => {
                            if (contact) {
                                this.$emit("new-contact", contact.id);
                                this.$store.dispatch(SHOW_SNACK, {
                                    message: `Contact ${contact.name} was added.`,
                                    type: "success"
                                });
                            } else {
                                this.$store.dispatch(SHOW_SNACK, {
                                    message: "No new contact was found.",
                                    type: "error"
                                });
                            }
                        })
                        .catch((response) => {
                              this.handleException(response)
                        })
                        .finally(() => {
                            this.$emit("processed");
                        });
                }, 2000);
            }, 1000);
        }
    }
};
</script>
