<template>
	<v-container id="clone-interests-container" fluid>
    <h1 class="heading"><b>{{ $t('COPPER_APPS.CLONE_INTERESTS_HEADING') }}</b></h1>
    <v-row>
      <v-col
        cols="12"
        class="pb-0 pt-0"
      >
        <ContactsAutocomplete
          :showAddNew="false"
          :error="error"
          @change="handleContactInput($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pb-0 pt-0 d-flex justify-content-center"
      >
        <v-btn
          rounded
          color="primary"
          dark
          height="30"
          @click="handleSaveClicked()"
        >
          {{ $t('COPPER_APPS.CLONE_INTERESTS_BUTTON') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="optionsDialog"
      persistent
      max-width="400px"
      @keydown.esc="optionsDialog = false"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t('COPPER_APPS.CLONE_INTERESTS_OPTIONS_MODAL_TEXT') }}</span>
        </v-card-title>
        <v-card-actions>
          <v-btn
            rounded
            color="primary"
            dark
            height="30"
            @click="addInterests"
          >
            {{ $t('COPPER_APPS.ADD_INTERESTS_BUTTON') }}
          </v-btn>
          <v-btn
            rounded
            color="primary"
            dark
            height="30"
            @click="overwriteInterests"
          >
            {{ $t('COPPER_APPS.OVERWRITE_INTERESTS_BUTTON') }}
          </v-btn>
          <v-spacer />
          <v-btn
            rounded
            color="red"
            dark
            height="30"
            @click="optionsDialog = false"
          >
            {{ $t('GENERAL.LABELS.CLOSE_BUTTON') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  GET_ENTITY_HAS_INTERESTS,
  ADD_CLIENT_INTERESTS,
  OVERWRITE_CLIENT_INTERESTS,
  SET_CLIENT_INTERESTS_LOADING
} from "@/store/interests.module";
import ContactsAutocomplete from "@/views/tagProcessor/Input/ContactsAutocomplete.vue";

export default {
  props: {
    entity: {
      type: Object
    }
  },

  components: {
    ContactsAutocomplete
  },

  data() {
    return {
      contact: null,
      error: false,
      optionsDialog: false
    };
  },

  methods: {
    handleContactInput(contact) {
      this.contact = contact;
      this.error = false;
    },

    handleSaveClicked() {
      if (!this.contact) {
        this.error = true;
        return;
      }

      this.$store.commit(SET_CLIENT_INTERESTS_LOADING, true);
      this.$store.dispatch(GET_ENTITY_HAS_INTERESTS, {
        entityTypeSlug: this.entity.type,
        entityExternalId: this.contact.external_id
      }).then(data => {
        if (data.has_interests) {
          this.optionsDialog = true;

          this.$store.commit(SET_CLIENT_INTERESTS_LOADING, false);
        } else {
          this.addInterests();
        }
      });
    },

    addInterests() {
      this.optionsDialog = false;
      this.$store.commit(SET_CLIENT_INTERESTS_LOADING, true);

      this.$store.dispatch(ADD_CLIENT_INTERESTS, {
        entityTypeSlug: this.entity.type,
        entityExternalId: this.contact.external_id,
        relatedEntityExternalId: this.entity.context.id,
      }).then(() => {
        this.$emit('saved');
      }).catch(() => {
        this.$store.commit(SET_CLIENT_INTERESTS_LOADING, false);
      });
    },

    overwriteInterests() {
      this.optionsDialog = false;
      this.$store.commit(SET_CLIENT_INTERESTS_LOADING, true);

      this.$store.dispatch(OVERWRITE_CLIENT_INTERESTS, {
        entityTypeSlug: this.entity.type,
        entityExternalId: this.contact.external_id,
        relatedEntityExternalId: this.entity.context.id,
      }).then(() => {
        this.$emit('saved');
      }).catch(() => {
        this.$store.commit(SET_CLIENT_INTERESTS_LOADING, false);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
#clone-interests-container {
  min-height: 400px;
}
</style>
