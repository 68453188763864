var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading || _vm.serverLoading}},[(_vm.headersPrepared)?_c('TopHeader',{attrs:{"filterable":_vm.filterable,"filters":_vm.filters,"filters-values":_vm.serverFilters || _vm.filtersValues,"search-value":_vm.searchString || _vm.searchValue,"hidden-headers":_vm.hiddenHeaders,"hidden-headers-values":_vm.hiddenHeadersValues},on:{"search":function($event){_vm.serverHandleSearchInput($event, _vm.options);
      _vm.$emit('search', $event);},"filters-change":function($event){_vm.serverHandleFiltersChange($event, _vm.options);
      _vm.$emit('filters-change', $event);},"headers-change":_vm.handleHeadersChange},scopedSlots:_vm._u([{key:"top-header-actions",fn:function(){return [_vm._t("top-header-actions")]},proxy:true}],null,true)}):_vm._e(),_c('v-data-table',_vm._b({key:_vm.tableKey,ref:"table",class:{ 'stripped': _vm.stripped },attrs:{"height":"500","fixed-header":"","headers":_vm.localHeaders,"options":_vm.options,"items":_vm.serverItems.length ? _vm.serverItems : _vm.items,"server-items-length":_vm.localTotalItems,"items-per-page":_vm.itemsPerPage,"item-class":_vm.rowClass,"mobile-breakpoint":"0","hide-default-footer":true,"item-key":_vm.itemKey},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headersSlots),function(slot){return {key:`header.${slot}`,fn:function({ header }){return [_vm._t(`header.${slot}`,function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(header.text)+" ")])]},{"header":header})]}}}),{key:"item",fn:function(item){return [_vm._t("item",function(){return [_c('tr',{class:_vm.rowClass(item.item)},_vm._l((item.headers),function(header,index){return _c('td',{class:{
              'text-center': header.align === 'center',
              'p-0': header.padding === false
            }},[(_vm.selectable && header.value === 'select')?_c('v-checkbox',{staticClass:"select-checkbox",attrs:{"hide-details":"","input-value":item.item.selected},on:{"change":function($event){return _vm.handleItemSelected(item.item, $event)}}}):_vm._t(`item.${header.value}`,function(){return [_c('Item',{key:`item-key-${header.value}-${index}`,attrs:{"itemData":{
                  item: item.item,
                  header: header,
                  value: _vm.getItemValue(item.item, header.value),
                  index: index
                }},on:{"item-updated":_vm.handleItemUpdated}})]},{"item":{
                item: item.item,
                header: header,
                value: _vm.getItemValue(item.item, header.value),
                index: index
              }})],2)}),0)]},{"item":item})]}},{key:"footer",fn:function({ props }){return [_c('div',{staticClass:"table-footer d-flex"},[_c('v-spacer'),_c('div',{staticClass:"table-footer-items"},[_vm._v(" "+_vm._s(`${_vm.$t('GENERAL.TABLE.TOTAL_ITEMS')}: ${props.pagination.itemsLength}`)+" ")])],1)]}}],null,true)},'v-data-table',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }