import { debounce } from 'lodash';
import userStateService from "@/common/userState.service.js";

export default {
  props: {
    serverDataOptions: {
      type: Object,
      default: undefined
    },

    persistentFilters: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      serverLoading: false,
      serverItems: [],
      serverFilters: this.filtersValues,
      queryParams: {
        page: 1,
        perPage: this.itemsPerPage
      },
      searchString: this.searchValue,
      isServerFetchingNow: false,
      lastPage: 1,
      fetchedPages: []
    }
  },

  mounted() {
    this.searchString = this.persistentFilters ?
      userStateService.getState(`table-state.${this.name}.searchString`, '') :
      '';

    this.$emit('search', this.searchString);

    this.serverFilters = this.persistentFilters ?
      userStateService.getState(`table-state.${this.name}.serverFilters`, {}) :
      {};

    this.$emit('filters-change', this.serverFilters);

    this.options = userStateService.getState(`table-state.${this.name}.options`, {
      groupBy: this.groupBy || [],
      groupDesc: this.groupDesc || [],
      itemsPerPage: this.itemsPerPage,
      multiSort: this.multiSort || true,
      mustSort: this.mustSort || false,
      page: 1,
      sortBy: this.sortBy || [],
      sortDesc: this.sortDesc || [false],
    });
  },

  methods: {
    fetchServerData(overwriteTableItems = true) {
      if (!this.isServerFetching()) {
        return;
      }

      return new Promise((resolve, reject) => {
        this.serverLoading = true;

        if (overwriteTableItems) {
          this.queryParams.page = 1;
        }

        let params = {
          ...(this.serverDataOptions.params || {}),
          ...this.queryParams,
          search: this.searchString,
          filters: this.serverFilters
        };

        this.$store
          .dispatch(this.serverDataOptions.method, params)
          .then((response) => {
            if (overwriteTableItems) {
              this.serverItems = response.data;
              this.fetchedPages = [];

              this.$refs.table.$el
                .querySelector('.v-data-table__wrapper').scrollTop = 0;

              this.$emit('overwritten-table-items');
            }

            this.fetchedPages.push(response.meta.current_page);
            this.fetchedPages.sort((a, b) => (a - b));

            this.localTotalItems = response.meta.total;
            this.lastPage = response.meta.last_page;

            this.$emit('fetched-server-data', {
              params: params,
              totalItems: this.localTotalItems
            });

            resolve(response);
          }).catch((error) => {
            reject(error);
          }).finally(() => {
            this.serverLoading = false;
          });
      });
    },

    serverHandleOptionsUpdate(options) {
      userStateService.setState(
        `table-state.${this.name}.options`,
        options
      );

      this.queryParams = {...options};

      this.fetchServerData();
    },

    serverHandleSearchInput: debounce(function (searchString, options) {
      let newSearchString = searchString.length > 2 ? searchString : '';

      if (this.searchString !== newSearchString) {
        userStateService.setState(
          `table-state.${this.name}.searchString`,
          newSearchString
        );

        this.searchString = newSearchString;

        this.queryParams = {...options};

        this.fetchServerData();
      }
    }, 1500),

    serverHandleFiltersChange(filters, options) {
      userStateService.setState(
        `table-state.${this.name}.serverFilters`,
        filters
      );

      this.serverFilters = filters;

      this.queryParams = {...options};

      this.fetchServerData();
    },

    serverHandleScrollToBottom() {
      this.isServerFetchingNow = true;

      const scrolledEl = this.$refs.table.$el
        .querySelector('.v-data-table__wrapper');

      this.queryParams.page = this.fetchedPages[this.fetchedPages.length - 1] + 1;

      this.fetchServerData(false).then((response) => {
        this.serverItems = [...this.serverItems, ...response.data];

        this.$nextTick(() => {
          if (this.serverItems.length > 200) {
            const firstRowsHeight = this.getFirstRowsHeight();
            const scrollTop = scrolledEl.scrollTop;

            this.serverItems = this.serverItems.slice(this.itemsPerPage);
            this.fetchedPages = this.fetchedPages.slice(1);

            this.$nextTick(() => {
              scrolledEl.scrollTop = scrollTop - firstRowsHeight;
            });
          }

          this.isServerFetchingNow = false;
        });
      });
    },

    serverHandleScrollToTop() {
      this.isServerFetchingNow = true;

      const scrolledEl = this.$refs.table.$el
        .querySelector('.v-data-table__wrapper');

      this.queryParams.page = this.fetchedPages[0] - 1;

      this.fetchServerData(false).then((response) => {
        this.serverItems = [...response.data, ...this.serverItems];

        this.$nextTick(() => {
          if (this.serverItems.length > 200) {
            const firstRowsHeight = this.getFirstRowsHeight();
            const scrollTop = scrolledEl.scrollTop;

            this.serverItems = this.serverItems.slice(0, this.itemsPerPage * -1);
            this.fetchedPages.pop();

            this.$nextTick(() => {
              scrolledEl.scrollTop = scrollTop + firstRowsHeight;
            });
          }

          this.isServerFetchingNow = false;
        });
      });
    },

    getFirstRowsHeight() {
      const rows = this.$refs.table.$el.querySelectorAll('tr');

      let rowsHeight = 0;
      for (let i = 0; i < this.itemsPerPage; i++) {
        if (rows[i]) {
          rowsHeight += rows[i].offsetHeight;
        }
      }

      return rowsHeight;
    },

    deleteServerItem(key) {
      this.serverItems = this.serverItems.filter((item) => item[this.itemKey] !== key);
    },

    isServerFetching() {
      return this.serverDataOptions && this.serverDataOptions.method;
    }
  }
};