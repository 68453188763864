import { get, set } from "lodash";

export default {
  setState(key, value) {
    let state = JSON.parse(localStorage.getItem('user-state'));
    if (!state) {
      state = {};
    }

    set(state, key, value);

    localStorage.setItem('user-state', JSON.stringify(state));
  },

  getState(key, defaultValue = undefined) {
    let state = JSON.parse(localStorage.getItem('user-state'));

    return get(state, key, defaultValue);
  }
};
