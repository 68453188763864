<template>
	<v-container fluid>
    <v-overlay
      :absolute="true"
      :value="error"
    >
      <v-alert
        prominent
        type="error"
      >
        <v-row align="center">
          <v-col class="grow">
            {{ $t('GENERAL.UNEXPECTED_ERROR') }}
          </v-col>
          <v-col class="shrink">
            <v-btn color="primary" @click="handleReloadPageClick()">{{ $t('GENERAL.LABELS.RELOAD_PAGE') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <h1 class="heading"><b>{{ $t('COPPER_APPS.SHOW_PRICE_LIST_HEADING') }}</b></h1>
    <v-row>
      <v-col
        cols="12"
      >
        <div class="price-list-date mb-2">
          <b>{{ $t('GENERAL.LABELS.DATE') }}</b>: {{ priceList.created_at }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
      <Table
        name="copper-embedded-apps-show-price-list-table"
        :headers="headers"
        :items="priceList.positions"
        :totalItems="priceList.positions.length"
        dense
        stripped
        fixed-header
      >
        <template v-slot:item.transport_price="{ item }">
          <div class="d-flex justify-content-center">
            <span v-if="item.item.missing_transport_price" class="text-danger">---</span>
            <span v-else>{{ item.item.tansport_price }}</span>
          </div>
        </template>
      </Table>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pt-2 mb-2 d-flex justify-content-center"
      >
        <v-btn
          class="mx-2"
          rounded
          color="primary"
          dark
          height="30"
          :loading="printViewLoading"
          @click="openPrintView()"
        >
          {{ $t('GENERAL.LABELS.PRINT_VIEW') }}
        </v-btn>
        <v-btn
          class="mx-2"
          rounded
          color="primary"
          dark
          height="30"
          :loading="downloadLoading"
          @click="download()"
        >
          {{ $t('GENERAL.LABELS.DOWNLOAD') }}
        </v-btn>
        <v-btn
          class="mx-2"
          rounded
          color="primary"
          dark
          height="30"
          @click="$emit('close')"
        >
          {{ $t('GENERAL.LABELS.CLOSE') }}
        </v-btn>
      </v-col>
    </v-row>
    <HtmlEditor
      ref="html-preview-dialog"
      :hideButton="'true'"
      :getContent="getHtmlPreviewContent"
    />
  </v-container>
</template>

<script>
import Table from "@/views/partials/v-table/Table.vue";
import HtmlEditor from "@/views/partials/content/HtmlEditor.vue";
import {
  SHOW_OPPORTUNITY_PRICE_LIST,
  GET_OPPORTUNITY_PRICE_LIST_PREVIEW_CONTENT
} from "@/store/opportunityPriceLists.module";

export default {
  props: {
    entity: {
      type: Object
    },

    vimsEntity: {
      type: Object
    }
  },

  components: {
    Table,
    HtmlEditor
  },

  data() {
    return {
      error: false,
      priceList: {
        created_at: '',
        positions: []
      },
      htmlPreviewContent: '',
      printViewLoading: false,
      downloadLoading: false,
      headers: [
        {
          text: this.$t('GENERAL.LABELS.OPPORTUNITY_REQUIREMENTS'),
          value: 'position_name',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('GENERAL.LABELS.PRICE_LIST'),
          value: 'offer_attributes',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('GENERAL.LABELS.PRICE'),
          value: 'base_price',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('GENERAL.LABELS.TRANSPORT_PRICE'),
          value: 'transport_price',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('GENERAL.LABELS.TOTAL_PRICE'),
          value: 'total_price',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('GENERAL.LABELS.INCOTERMS'),
          value: 'incoterms',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('GENERAL.LABELS.MATCH_TYPE'),
          value: 'match_type',
          align: 'center',
          sortable: false
        },
      ]
    };
  },

  mounted() {
    this.getPriceList();
  },

  methods: {
    getPriceList() {
      this.$store.dispatch(SHOW_OPPORTUNITY_PRICE_LIST, {
        opportunityId: this.entity.context.id,
        priceListId: this.$route.query.priceListId
      }).then(response => {
        this.priceList = response;

        this.$nextTick(() => {
          this.priceList.positions.forEach((position) => {
            if (position.is_updated) {
              document.getElementsByClassName(`row-item-key-${position.id}`)[0].style['background-color'] = '#edf50a';
            }
          });
        });
      }).catch(() => {
        this.error = true;
      })
    },

    download() {
      this.downloadLoading = true;

      this.$store.dispatch(GET_OPPORTUNITY_PRICE_LIST_PREVIEW_CONTENT, {
        opportunityId: this.entity.context.id,
        priceListId: this.$route.query.priceListId
      })
        .then(response => {
          var element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," +
              encodeURIComponent(
                response.content
                  .replace(/<p><strong>/g, "")
                  .replace(/<\/strong><\/p>/g, "\n")
                  .replace(/<p>/g,"")
                  .replace(/<\/p>/g,"\n")
              )
          );

          element.setAttribute("download", this.vimsEntity.name + ".txt");

          element.style.display = "none";
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);

          this.downloadLoading = false;
        }).catch(() => {
          this.error = true;
        });
    },

    openPrintView() {
      this.printViewLoading = true;

      this.$store.dispatch(GET_OPPORTUNITY_PRICE_LIST_PREVIEW_CONTENT, {
        opportunityId: this.entity.context.id,
        priceListId: this.$route.query.priceListId
      })
        .then(response => {
          this.htmlPreviewContent = response.content;

          this.$refs['html-preview-dialog'].showDialog();

          this.printViewLoading = false;
        }).catch(() => {
          this.error = true;
        });
    },

    getHtmlPreviewContent() {
      return this.htmlPreviewContent;
    },

    handleReloadPageClick() {
      window.location.reload();
    },
  }
}
</script>

<style lang="scss" scoped>
.price-list-date {
  font-size: 15px;
}
</style>
