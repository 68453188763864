import { render, staticRenderFns } from "./InterestsList.vue?vue&type=template&id=1d1361e8&scoped=true"
import script from "./InterestsList.vue?vue&type=script&lang=js"
export * from "./InterestsList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d1361e8",
  null
  
)

export default component.exports