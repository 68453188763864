<template>
  <div>
    <v-card-title class="align-items-center">
      <v-text-field
        v-if="filterable"
        dense
        append-icon="mdi-magnify"
        :label="$t('GENERAL.LABELS.SEARCH')"
        class="top-header-search-input pt-0 mt-0 mx-2"
        single-line
        hide-details
        :value="searchValue"
        @input="$emit('search', $event);"
      ></v-text-field>
      <slot name="top-header-actions">
        <v-spacer></v-spacer>
      </slot>
      <v-menu
        v-if="hiddenHeaders && hiddenHeaders.length"
        bottom
        left
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="indigo"
            v-bind="attrs"
            v-on="on"
          >
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-format-list-bulleted-square
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.ADDITIONAL_COLUMNS') }}
              </span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-card>
          <v-list class="mt-2 pb-0">
            <v-list-item>
              <v-autocomplete
                dense
                chips
                small-chips
                multiple
                solo
                return-object
                :value="localHiddenHeadersValues"
                :label="$t('GENERAL.LABELS.HEADERS')"
                :items="hiddenHeaders"
                item-value="value"
                item-text="text"
                @change="handleHiddenHeadersChange($event)"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click:close="handleRemoveHiddenHeaderSelection(data.item)"
                  >
                    {{ data.item.text }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-menu
        v-if="filterable && filters && filters.length"
        bottom
        left
        max-height="70vh"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="indigo"
            v-bind="attrs"
            v-on="on"
          >
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-filter-variant
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.FILTERS') }}
              </span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-card>
          <v-list class="mt-2 pb-0">
            <v-list-item :key="index" v-for="(filterData, index) in filters">
              <v-text-field
                v-if="filterData.type === 'text'"
                dense
                :label="filterData.label"
                v-model="localFiltersValues[filterData.key]"
                clearable
                @input="handleTextFilterChange()"
              />
              <DatePicker
                v-else-if="filterData.type === 'date'"
                :label="filterData.label"
                v-model="localFiltersValues[filterData.key]"
                clearable
                :without-hint="true"
                :without-icon="true"
                class="filter-item"
                @input="handleFiltersChange"
              />
              <v-autocomplete
                  v-else-if="filterData.type === 'select'"
                  dense
                  chips
                  small-chips
                  multiple
                  v-model="localFiltersValues[filterData.key]"
                  :label="filterData.label"
                  :items="filterData.items"
                  :item-value="filterData['item-value']"
                  :item-text="filterData['item-text']"
                  @change="handleFiltersChange"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click:close="handleRemoveSelectFilterSelection(filterData.key, data.item[filterData['item-value']])"
                  >
                    {{ data.item[filterData['item-text']] }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-list-item>
          </v-list>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="handleClearFilters"
            >
              Clear filters
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-card-title>
    <div class="filters-container" v-if="filters && filters.length">
      <span v-for="(filter, key) in localFiltersValues" :key="'server-filters-' + key">
        <template v-if="Array.isArray(filter) && filter.length">
          <v-chip
            v-for="value in filter"
            class="mx-1 my-1"
            :key="`${key} - ${value}`"
            small
            close
            @click:close="handleRemoveSelectFilterSelection(key, value)"
          >
            {{ getFilterText(key, value) }}
          </v-chip>
        </template>
        <v-chip
          v-else-if="!Array.isArray(filter) && filter"
          class="mx-1 my-1"
          :key="`${key} - ${filter}`"
          small
          close
          @click:close="handleRemoveFilterSelection(key)"
        >
          {{ getFilterText(key, filter) }}
        </v-chip>
      </span>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import DatePicker from "@/views/partials/form/DatePicker.vue";

export default {
  components: {
    DatePicker
  },

  props: {
    filterable: {
      type: Boolean,
      default: false
    },

    filters: {
      type: Array,
      default: () => []
    },

    searchValue: {
      type: String,
      default: ''
    },

    filtersValues: {
      type: Object,
      default: () => ({})
    },

    hiddenHeaders: {
      type: Array,
      default: undefined
    },

    hiddenHeadersValues: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      localFiltersValues: this.filtersValues,
      localHiddenHeadersValues: this.hiddenHeadersValues
    };
  },

  mounted() {
    if (this.filters.length) {
      this.initFiltersValues();
    }
  },

  watch: {
    filters() {
      this.initFiltersValues();
    },

    filtersValues() {
      this.initFiltersValues();
    }
  },

  methods: {
    initFiltersValues() {
      this.localFiltersValues = this.filtersValues;

      this.filters.forEach((filter) => {
        if (!this.localFiltersValues[filter.key]) {
          this.localFiltersValues[filter.key] = filter.type === 'select' ? [] : '';
        }
      });
    },

    handleFiltersChange() {
      this.$emit('filters-change', this.localFiltersValues);
    },

    getFilterText(key, value) {
      const filter = this.filters.find((filter) => filter.key === key);

      if (!filter) return '---';

      if (filter.type === 'select') {
        const filterValue = filter.items.find((item) => item[filter['item-value']] === value);

        return `${filter.label}: ${filterValue ? filterValue[filter['item-text']] : value}`;
      }

      return `${filter.label}: ${value}`;
    },

    handleTextFilterChange: debounce(function () {
      this.handleFiltersChange();
    }, 1500),

    handleRemoveSelectFilterSelection(filterKey, item) {
      const index = this.localFiltersValues[filterKey].indexOf(item);
      if (index >= 0) {
        this.localFiltersValues[filterKey].splice(index, 1);

        this.handleFiltersChange();
      }
    },

    handleRemoveFilterSelection(filterKey) {
      this.localFiltersValues[filterKey] = '';

      this.handleFiltersChange();
    },

    handleClearFilters() {
      this.filters.forEach((filter) => {
        if (Array.isArray(this.localFiltersValues[filter.key])) {
          this.localFiltersValues[filter.key].splice(0, this.localFiltersValues[filter.key].length);
        } else {
          this.localFiltersValues[filter.key] = '';
        }
      });

      this.handleFiltersChange();
    },

    handleHiddenHeadersChange(values) {
      this.localHiddenHeadersValues = values;

      this.$emit('headers-change', this.localHiddenHeadersValues);
    },

    handleRemoveHiddenHeaderSelection(item) {
      const index = this.localHiddenHeadersValues.findIndex(header => header.value === item.value);

      if (index >= 0) {
        this.localHiddenHeadersValues.splice(index, 1);

        this.$emit('headers-change', this.localHiddenHeadersValues);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-text-field__details) {
    margin-bottom: 0 !important;
}

:deep(.v-text-field) {
    padding-top: 0 !important;
}

.filters-container {
  padding: 0 16px;
}

.filter-item {
  width: 100%;
}

.top-header-search-input {
  max-width: 300px;
}
</style>